import React, { useState, useCallback, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import ClampLines from "react-clamp-lines";

import Loading from "../assets/Loading";

const LoadingWrapper = styled.div`
  display: flex;
`;

const LoadingText = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin-left: 10px;
`;

const Message = styled.div`
  div {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fontSize.normal};
    color: ${({ theme }) => theme.colors.gray};
    line-height: 1;
    overflow-wrap: break-word;
  }
`;

const MessagePreview = ({ decryptMessage, encrypted, id, loadingText }) => {
  const [message, setMessage] = useState(null);
  const theme = useTheme();

  const decrypt = useCallback(
    async m => {
      // Let's not decrypt multiple times
      if (message) {
        return;
      }
      const decrypted = await decryptMessage(encrypted);
      setMessage(decrypted);
    },
    [encrypted, decryptMessage, message],
  );

  useEffect(() => {
    decrypt(encrypted);
  }, [encrypted, decrypt]);

  if (!message) {
    return (
      <LoadingWrapper>
        <Loading
          outerColor={theme.colors.borderDark}
          innerColor={theme.colors.default}
        />
        <LoadingText>{loadingText}</LoadingText>
      </LoadingWrapper>
    );
  }

  return (
    <Message>
      <ClampLines text={message} lines={2} buttons={false} id={id} />
    </Message>
  );
};

export default MessagePreview;
