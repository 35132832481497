const STATUS_NEW = "new";
const STATUS_PENDING = "pending";
const STATUS_ACCEPTED = "accepted";
const STATUS_DECLINED = "declined";
const STATUS_CANCELED = "canceled";
const STATUS_COMPLETED = "completed";

const STATUSES_ALL = [
  STATUS_PENDING,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_CANCELED,
  STATUS_COMPLETED,
];
const STATUSES_ACTIVE = [STATUS_PENDING, STATUS_ACCEPTED];
const STATUSES_CLOSED = [STATUS_DECLINED, STATUS_CANCELED, STATUS_COMPLETED];

const FILTER_RECENT = "recent";
const FILTER_OLDER = "older";

const DESKTOP_BREAKPOINT = 900;

export {
  STATUS_NEW,
  STATUS_PENDING,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUSES_ALL,
  STATUSES_ACTIVE,
  STATUSES_CLOSED,
  FILTER_RECENT,
  FILTER_OLDER,
  DESKTOP_BREAKPOINT,
};
